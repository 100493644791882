// Copyright © 2024 CATTLEytics Inc.

import { Badge, BadgeProps } from 'react-bootstrap';
import * as Icons from 'react-icons/io5';

import { TaskCategory } from '../../shared';

interface TaskCategoryBadgeProps extends BadgeProps {
  category: TaskCategory;
}

/**
 * Nicer display for category badge.
 */
export function TaskCategoryBadge({ category, ...props }: TaskCategoryBadgeProps): JSX.Element {
  const Icon = category.iconName
    ? Icons[category.iconName as keyof typeof Icons]
    : Icons.IoDocumentTextOutline;

  return (
    <Badge {...props} pill>
      <Icon className="me-1" />
      {category.name}
    </Badge>
  );
}
