// Copyright © 2023 CATTLEytics Inc.

import { endOfDay, startOfDay } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Stack, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../common/components/Button';
import ButtonCreate from '../../common/components/ButtonCreate';
import Page from '../../common/components/Page';
import { TaskPeriod } from '../../common/enums';
import { api } from '../../common/utilities';
import { useFeatureFlags } from '../../settings/hooks/useFeatureFlags';
import { ApiResourceV1, HttpMethod, QueryKey, TaskCategory } from '../../shared';
import { CategoryTab } from './CategoryTab';
import { TaskModal } from './TaskModal';
import TasksList from './TasksList';

interface RouteParams {
  /**
   * Tab key to make active on render.
   */
  tabKey: string;
}

/**
 * Routable component to list tasks.
 */
export const TasksPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { tabKey } = useParams<RouteParams>();
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const [taskModalVisible, setTaskModalVisible] = useState<boolean>(false);

  const query = useQuery<TaskCategory[]>(QueryKey.TaskCategories, () =>
    api<TaskCategory[]>(HttpMethod.Get, ApiResourceV1.TaskCategories, {
      params: { limit: '6', sortField: 'order', sortDirection: 'ASC' },
    }),
  );

  const categoryTabs = useMemo(
    () =>
      query.data &&
      query.data.map((category) => (
        <Tab
          eventKey={String(category.id)}
          key={category.id}
          title={<CategoryTab category={category} />}
        >
          <TasksList filterByCategoryId={category.id} showCompleted={false} />
        </Tab>
      )),
    [query.data],
  );

  return (
    <Page
      breadcrumbs={[{ label: t('dairyLogsPage|title'), to: '/dairy-logs' }]}
      buttons={
        <Stack className="flex-column flex-md-row" direction="horizontal" gap={2}>
          {featureFlags['TaskTemplates'] === 'True' && (
            <Button
              onClick={(): void => history.push('/tasks-templates')}
              size="lg"
              variant="outline-primary"
            >
              Templates
            </Button>
          )}
          <ButtonCreate
            label={t('tasksPage|createButton')}
            onClick={(): void => setTaskModalVisible(true)}
          />
        </Stack>
      }
      title={t('tasksPage|title')}
    >
      <Tabs
        activeKey={tabKey}
        className="mb-3"
        id="tasks-tabs"
        mountOnEnter={true}
        onSelect={(k): void => {
          if (k) {
            history.push(`/tasks/${k}`);
          }
        }}
      >
        <Tab eventKey="all" title={t('tasksPage|tabAllLabel')}>
          <h2 className={'mt-5'}>{t('tasksPage|overdueLabel')}</h2>
          <TasksList
            dueDateEnd={startOfDay(new Date())}
            showCompleted={false}
            showPagination={false}
          />
          <h2 className={'mt-5'}>{t('tasksPage|todayLabel')}</h2>
          <TasksList
            dueDatePeriod={TaskPeriod.Daily}
            showCompleted={false}
            showPagination={false}
          />
          <h2 className={'mt-5'}>{t('tasksPage|thisWeekLabel')}</h2>
          <TasksList
            dueDatePeriod={TaskPeriod.Weekly}
            showCompleted={false}
            showPagination={false}
          />
          <h2 className={'mt-5'}>{t('tasksPage|wheneverLabel')}</h2>
          <TasksList
            dueDatePeriod={TaskPeriod.Whenever}
            showCompleted={false}
            showPagination={false}
          />
          <h2 className={'mt-5'}>{t('tasksPage|completedLabel')}</h2>
          <TasksList
            completedDateEnd={endOfDay(new Date())}
            completedDateStart={startOfDay(new Date())}
            onlyCompleted={true}
            showPagination={false}
          />
        </Tab>

        <Tab eventKey="completed" title={t('tasksPage|tabCompletedLabel')}>
          <TasksList limit={1000} onlyCompleted={true} />
        </Tab>
        {categoryTabs}
      </Tabs>

      {taskModalVisible && <TaskModal onClose={(): void => setTaskModalVisible(false)} />}
    </Page>
  );
};
