// Copyright © 2023 CATTLEytics Inc.

import { addDays, addHours, endOfDay, format } from 'date-fns';
import React, {
  ChangeEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ButtonGroup, Col, Form, FormGroup, Placeholder, Row } from 'react-bootstrap';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import AlertErrorForModal from '../../common/components/AlertErrorForModal';
import Button from '../../common/components/Button';
import { ButtonVariant } from '../../common/components/Button';
import ButtonModal from '../../common/components/ButtonModal';
import ImageUploaded from '../../common/components/ImageUploaded';
import ImageUploading from '../../common/components/ImageUploading';
import { AnimalMentionsTextArea } from '../../common/components/mentions';
import Modal from '../../common/components/Modal';
import NoImageUploaded from '../../common/components/NoImageUploaded';
import Required from '../../common/components/Required';
import { TaskCategorySelect } from '../../common/components/TaskCategorySelect';
import TaskStatusSelect from '../../common/components/TaskStatusSelect';
import TimeSelect, { TimeSelectEODValue } from '../../common/components/TimeSelect';
import User from '../../common/entities/user';
import { useFileService } from '../../common/hooks/useFileService';
import { ImageUrl } from '../../common/interfaces';
import AuthContext from '../../common/store/auth-context';
import { api, IconCreate, IconDelete, IconSave } from '../../common/utilities';
import { ImageSize, resizeImage } from '../../common/utilities/imageResize';
import {
  Animal,
  ApiResourceV1,
  dateFormat,
  HttpMethod,
  nameTag,
  QueryKey,
  Task,
  TaskPriority,
  TaskStatus,
} from '../../shared';
import UserAutocomplete from '../../users/components/UserAutocomplete';

const getFormattedDate = (date?: string): string =>
  date ? format(new Date(date), dateFormat) : '';

const getFormattedTime = (date?: string): string =>
  date ? format(new Date(date), 'p') : '6:00 AM';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Animal Object from animal card.
   */
  animal?: Animal;

  /**
   * Animal ID to associate this task with
   */
  animalId?: number;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;

  /**
   * Callback on successful save. Note: onClose is NOT called when onSave is set
   * @param task
   */
  onSave?: (task: Task) => void;

  /**
   * Pen ID to associate with this task
   */
  penId?: number;

  /**
   * ID of task to edit in this modal.
   */
  taskId?: number;
}

/**
 * Note modal component for creating/editing notes.
 */
export const TaskModal = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const fileService = useFileService();
  const auth = useContext(AuthContext);

  const [showAddTaskCategoryModal, setShowAddTaskCategoryModal] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);

  // If task is opened through animal card, preset the description with a mention of given animal
  const [description, setDescription] = useState<string>(
    props.animal
      ? '@[' +
          nameTag(props.animal.primaryTag, props.animal.name) +
          ']' +
          '(' +
          String(props.animal.id) +
          ')'
      : '',
  );
  const [notes, setNotes] = useState<string>('');
  const [dueDate, setDueDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [startTimeValue, setStartTimeValue] = useState<Option>('6:00 AM');
  const [status, setStatus] = useState<TaskStatus>(TaskStatus.Pending);
  const [priority, setPriority] = useState<TaskPriority>(TaskPriority.Medium);
  const [taskCategoryId, setTaskCategoryId] = useState<number>();
  const [user, setUser] = useState<User>();
  const [validated, setValidated] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [busyFileUploading, setBusyFileUploading] = useState<boolean>(false);
  const [imageUrls, setImageUrls] = useState<ImageUrl[]>([]);
  const [showUploadImageForm, setShowUploadImageForm] = useState<boolean>(false);

  const dueDateFormatted = useMemo(() => getFormattedDate(dueDate), [dueDate]);
  const startDateFormatted = useMemo(() => getFormattedDate(startDate), [startDate]);

  // const [data, setData] = useState<Animal | undefined>(props.animal ? props.animal : undefined);

  const query = useQuery<Task | undefined>(
    [QueryKey.Tasks, props.taskId],
    () => api(HttpMethod.Get, `${ApiResourceV1.Tasks}/${props.taskId}`),
    {
      enabled: !!props.taskId,
    },
  );

  useEffect(() => {
    if (!query.data) {
      return;
    }
    const data = query.data;
    setDescription(data.description);
    setTaskCategoryId(data.taskCategoryId);
    if (data.notes) {
      setNotes(data.notes);
    }
    if (data.startDate) {
      setStartDate(data.startDate);

      setStartTimeValue(getFormattedTime(data.startDate));
    }
    if (data.dueDate) {
      setDueDate(data.dueDate);
    }
    if (data.status) {
      setStatus(data.status);
    }
    if (data.priority) {
      setPriority(data.priority);
    }

    if (data.status === TaskStatus.Completed && data.completedUser) {
      setUser(data.completedUser);
    } else if (data.assignedUser) {
      setUser(data.assignedUser);
    } else if (data.user) {
      setUser(data.user);
    }
    if (data.imageUrls && !data.imageUrlsSigned) {
      setImageUrls(data.imageUrls.map((url) => ({ key: url })));
    }
    if (data.imageUrlsSigned) {
      setImageUrls(data.imageUrlsSigned);
    }
  }, [query.data]);

  const setHighPriority = useCallback(() => {
    setDueDate(addHours(new Date(), 1).toISOString());
    setPriority(TaskPriority.Highest);
  }, [setDueDate, setPriority]);

  useEffect(() => {
    const invalidElements = document.querySelectorAll('input.form-control:invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].closest('.form-group')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [validated]);

  const mutation = useMutation<Task>(
    () => {
      const payload: Partial<Task> = {
        description,
        dueDate: dueDate ? dueDate : null,
        notes: notes ?? undefined, // if notes is '' then notes will be '' and NOT undefined
        [status === TaskStatus.Completed ? 'completedUserId' : 'assignedUserId']: user
          ? user.id
          : undefined,
        startDate: startDate
          ? new Date(`${getFormattedDate(startDate)} ${startTimeValue}`).toISOString()
          : null,
        status,
        taskCategoryId,
        priority,
        completed: status === TaskStatus.Completed || status === TaskStatus.Skipped,
        imageUrls: imageUrls.map((url) => url.key),
      };

      if (props.taskId) {
        return api(HttpMethod.Patch, `${ApiResourceV1.Tasks}/${props.taskId}`, { body: payload });
      } else {
        return api(HttpMethod.Post, `${ApiResourceV1.Tasks}`, { body: payload });
      }
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(QueryKey.Tasks);
      },
    },
  );

  const queryClient = useQueryClient();

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    const valid = form.checkValidity();

    // mark the form as having its validity checked
    setValidated(true);

    if (!valid) {
      return;
    }

    setErrorMessage('');
    try {
      const task = await mutation.mutateAsync();
      if (task) {
        props.onSave?.(task);
      }
      props.onClose();
    } catch (err) {
      console.error('Task could not be saved.', err);
      setErrorMessage(t('taskModal|taskNotSavedError'));
    }
  };

  const removeImage = (index: number): void => {
    const updatedImageUrls = [...imageUrls];
    delete updatedImageUrls[index];
    setImageUrls(Object.values(updatedImageUrls));
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = ((event.target as HTMLInputElement).files as FileList)[0];
    setBusyFileUploading(true);
    await uploadFile(await resizeImage(file, ImageSize.Large));
    setBusyFileUploading(false);
  };

  const uploadFile = async (file: File): Promise<void> => {
    const fileResult = await fileService.upload(file, undefined, auth.siteId);
    if (fileResult.signedUrl) {
      const newImageUrls = [...imageUrls];
      newImageUrls.push({ key: fileResult.key, signedUrl: fileResult.signedUrl });
      setImageUrls(newImageUrls);
      setShowUploadImageForm(false);
    }
  };

  // TODO: rewrite logic to accept multiple animal selections
  // const onTypeaheadSelect = (name: string, value?: Animal | Pen): void => {
  //   const newData = { ...data };
  //   if (!value) {
  //     newData[name.substr(0, name.length - 2)] = null;
  //     newData[name] = null;
  //   } else {
  //     newData[name.substr(0, name.length - 2)] = value;
  //     newData[name] = value.id;
  //   }
  //   setData(newData);
  // };

  function convertFromEndOfDay(time: Option): Option {
    return time === t('timeSelect|endOfDay') ? TimeSelectEODValue : time;
  }

  function convertToEndOfDay(time: Option): Option {
    return time === TimeSelectEODValue ? t('timeSelect|endOfDay') : time;
  }

  const fields = (
    <>
      <FormGroup className="form-group mb-3" controlId="formDescription">
        <Form.Label>
          {t('Description')} <Required />
        </Form.Label>
        <AnimalMentionsTextArea
          name="description"
          onChange={(evt): void => setDescription(evt.target.value)}
          required
          rows={4}
          value={description}
        />
        <Form.Text className={'text-muted'}>{t('taskModal|taskDescriptionInstructions')}</Form.Text>
        <Form.Control.Feedback type={'invalid'}>
          {t('common|fieldRequiredFeedback')}
        </Form.Control.Feedback>
      </FormGroup>

      <Form.Group className="form-group mb-3" controlId="formPriority">
        <Form.Check
          aria-label={t('High Priority')}
          checked={priority === TaskPriority.Highest}
          label={t('High Priority')}
          onChange={(event): void => {
            if (event.target.checked) {
              setHighPriority();
            } else {
              setPriority(TaskPriority.Medium);
            }
          }}
        />
      </Form.Group>

      {/* TODO: rewrite logic to accept multiple animal selections */}
      {/* <Form.Group className="form-group mb-3" controlId="formAnimal">
        <Form.Label>{t('Animal')}</Form.Label>
        <AnimalAutocomplete
          id={'animal'}
          name={'animalId'}
          onSelect={(animal): void => onTypeaheadSelect('animalId', animal as Animal | undefined)}
          required={false}
          selected={props.animal ? props.animal : undefined}
        />
      </Form.Group> */}

      <Form.Group className="form-group mb-3" controlId="formStatus">
        <Form.Label>
          {t('Status')} <Required />
        </Form.Label>
        <TaskStatusSelect
          aria-label={t('Status')}
          onChange={(event): void => setStatus(event.target.value as TaskStatus)}
          onCloseAddModal={(): undefined => undefined}
          required={true}
          showAddModal={false}
          value={status}
        />
        <Form.Text className={'text-muted'}>{t('The current status of this task')}</Form.Text>
      </Form.Group>

      <Form.Group className="form-group mb-3" controlId="formStartDate">
        <Form.Label>{t('Start Date')}</Form.Label>
        <Row>
          <Col md={startDate ? 6 : 12}>
            <Form.Control
              aria-label={t('Start Date')}
              onChange={(event): void => {
                setStartDate(new Date(`${event.target.value} GMT-0400`).toISOString());
              }}
              type={'date'}
              value={startDateFormatted}
            />
            <Form.Text className={'text-muted'}>
              {t('The date on which this task should be started')}
            </Form.Text>
          </Col>
          {startDate && (
            <Col md={6}>
              <TimeSelect
                className="mt-2 mt-md-0 time-dropdown"
                id="startTime"
                onChange={(e): void => setStartTimeValue(convertFromEndOfDay(e[0]))}
                required={true}
                validateEarly={true}
                validated={validated}
                value={convertToEndOfDay(startTimeValue)}
              />
            </Col>
          )}
        </Row>
      </Form.Group>

      <Form.Group className="form-group mb-3" controlId="formDueDate">
        <Form.Label>{t('Due Date')}</Form.Label>
        <ButtonGroup className="d-block">
          <Button onClick={(): void => setHighPriority()} type="button">
            {t('taskModal|asapButtonLabel')}
          </Button>
          <Button
            onClick={(): void => {
              setPriority(TaskPriority.Medium);
              setDueDate(endOfDay(new Date()).toISOString());
            }}
            type="button"
          >
            {t('Today')}
          </Button>
          <Button
            onClick={(): void => {
              setPriority(TaskPriority.Medium);
              setDueDate(endOfDay(addDays(new Date(), 7)).toISOString());
            }}
            type="button"
          >
            {t('This Week')}
          </Button>
          <Button
            onClick={(): void => {
              setPriority(TaskPriority.Medium);
              setDueDate('');
            }}
            type="button"
          >
            {t('Whenever')}
          </Button>
        </ButtonGroup>
        <div className="d-flex flex-row align-items-center">
          <Form.Control
            aria-label={t('Due Date')}
            className="mt-2 flex-grow-1"
            onChange={(event): void => {
              setDueDate(endOfDay(new Date(`${event.target.value} GMT-0400`)).toISOString());
            }}
            type={'date'}
            value={dueDateFormatted}
          />
          {dueDate && (
            <Form.Text className="text-muted ps-2 text-nowrap">
              {format(new Date(dueDate), 'hh:mm a')}
            </Form.Text>
          )}
        </div>
        <Form.Text className={'text-muted'}>
          {t('The date on which this task should be completed')}
        </Form.Text>
      </Form.Group>

      <Form.Group className="form-group mb-3" controlId="formTaskCategory">
        <Form.Label>{t('taskModal|categoryFieldLabel')}</Form.Label>

        <TaskCategorySelect
          onChange={(ev): void => setTaskCategoryId(parseInt(ev.target.value))}
          onCloseAddModal={(): void => setShowAddTaskCategoryModal(false)}
          showAddModal={showAddTaskCategoryModal}
          value={taskCategoryId}
        />

        <Form.Text className="text-muted">
          <span>{t('taskModal|categoryFieldHint')}</span>
          <Button
            onClick={(): void => setShowAddTaskCategoryModal(true)}
            size={'sm'}
            variant={'link'}
          >
            {t('taskModal|categoryFieldAddButton')}
          </Button>
        </Form.Text>
      </Form.Group>

      <Form.Group className="form-group mb-3" controlId="formUser">
        <Form.Label>
          {t(status === TaskStatus.Completed ? 'Completed By' : 'Assigned User')}
        </Form.Label>
        <UserAutocomplete
          id={'user'}
          multiple={false}
          name={'userId'}
          onCloseAddModal={(): void => setShowAddUserModal(false)}
          onSelect={(selected): void => {
            // since multiple is not set we can assume we are going to either get undefined or Animal
            if (!selected || Array.isArray(selected)) {
              // selected is undefined (can't actually not be an array because of multiple prop)
              return setUser(undefined);
            }
            setUser(selected);
          }}
          required={false}
          selected={user}
          showAddModal={showAddUserModal}
          validated={validated}
        />
        <Form.Text className="text-muted">
          <span>{t('Choose a {{value}} from the dropdown or', { value: 'user' })} </span>
          <Button onClick={(): void => setShowAddUserModal(true)} size={'sm'} variant={'link'}>
            {t('add user')}
          </Button>
        </Form.Text>
      </Form.Group>

      <FormGroup className="form-group mb-3" controlId="formNotes">
        <Form.Label>{t('Notes')}</Form.Label>
        <Form.Control
          as={'textarea'}
          name={'notes'}
          onChange={(event): void => setNotes(event.target.value)}
          rows={4}
          value={notes}
        />
        <Form.Text className={'text-muted'}>{t('taskModal|taskNotesInstructions')}</Form.Text>
      </FormGroup>

      <Form.Group className="form-group mb-3" controlId="formImages">
        <Form.Label>{t('taskModal|imageHeaderLabel')}</Form.Label>
        <div className={'d-flex flex-wrap justify-content-start mb-3 gap-2'}>
          {imageUrls.map((imageUrl, index) => (
            <div key={index}>
              {imageUrl.signedUrl && (
                <div className={'mt-3 text-center'}>
                  {!busyFileUploading && !imageUrls[index].signedUrl && <NoImageUploaded />}
                  {!busyFileUploading && imageUrls[index].signedUrl && (
                    <ImageUploaded
                      alt={t('taskModal|imageLabel')}
                      src={imageUrls[index].signedUrl ?? ''}
                    />
                  )}

                  <Button
                    className={'mt-1'}
                    icon={IconDelete}
                    onClick={(): void => {
                      removeImage(index);
                    }}
                    size={'sm'}
                    type={'button'}
                    variant={'outline-secondary'}
                  >
                    {t('taskModal|remove')}
                  </Button>
                </div>
              )}
            </div>
          ))}
          {!showUploadImageForm && (
            <div
              className={'border rounded mt-3 d-flex justify-content-center align-items-center'}
              style={{ width: '150px', height: '150px' }}
            >
              <Button
                className={''}
                icon={IconCreate}
                onClick={(): void => setShowUploadImageForm(true)}
                size={'sm'}
                style={{ height: '2rem' }}
                variant={'outline-secondary'}
              >
                {t('taskModal|addImageButtonLabel')}
              </Button>
            </div>
          )}
        </div>
        {showUploadImageForm && (
          <div className={'mb-3'}>
            <Form.Control name={'imageUrl'} onChange={handleFileUpload} type="file" />
            <Form.Text className="text-muted">{t('taskModal|uploadTaskImageText')}</Form.Text>
          </div>
        )}
        {busyFileUploading && <ImageUploading />}
      </Form.Group>
    </>
  );
  const placeholder = (
    <Placeholder animation={'glow'}>
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
      <Placeholder className={'mt-3'} size={'lg'} xs={6} />
      <Placeholder xs={12} />
    </Placeholder>
  );

  return (
    <Modal
      onClose={props.onClose}
      size={'lg'}
      title={`${props.taskId ? 'Edit' : 'Create'} Task`}
      visible={true}
    >
      <Form noValidate={true} onSubmit={onFormSubmit} validated={validated}>
        {query.isLoading ? placeholder : fields}
        <AlertErrorForModal message={errorMessage} />
        <div className="modal-footer modal-footer-in-form">
          <ButtonModal
            disabled={mutation.isLoading || query.isLoading}
            label={t('Cancel')}
            onClick={props.onClose}
            type={'button'}
            variant={ButtonVariant.Secondary}
          />
          <ButtonModal
            busy={mutation.isLoading}
            disabled={mutation.isLoading || query.isLoading}
            icon={IconSave}
            label={t('Save')}
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};
