// Copyright © 2023 CATTLEytics Inc.

import React, { useState } from 'react';
import {
  Accordion,
  Alert,
  Badge,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { useQuery } from 'react-query';

import { AnimalGroup, AnimalMilkingStatus, Info, Product, TaskTemplate } from '../../shared';
import { TaskTitleAutocomplete } from '../../tasks/components/TaskTitleAutocomplete';
import { AnimalReproductionStatus } from '../enums';
import {
  getEnv,
  IconCancel,
  IconCreate,
  IconDelete,
  IconDrag,
  IconDuplicate,
  IconEdit,
  IconSave,
  IconSignIn,
} from '../utilities';
import { api } from '../utilities';
import AnimalDiedBadge from './AnimalDiedBadge';
import AnimalGroupAutocomplete from './AnimalGroupAutocomplete';
import AnimalMilkingStatusBadge from './AnimalMilkingStatusBadge';
import AnimalReproductionStatusBadge from './AnimalReproductionStatusBadge';
import AnimalSoldBadge from './AnimalSoldBadge';
import Arrow from './Arrow';
import Button, { ButtonVariant } from './Button';
import ButtonModal from './ButtonModal';
import ButtonSave from './ButtonSave';
import ProductAutocomplete from './ProductAutocomplete';
import Toast from './Toast';

interface Props {
  className?: string;
}

/**
 * Routable component to show a single note.
 */
const StylesPage = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const [showToast, setShowToast] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<Product | Product[] | undefined>([]);
  const [selectedAnimalGroups, setSelectedAnimalGroups] = useState<
    AnimalGroup | AnimalGroup[] | undefined
  >([]);

  const [taskTitle, setTaskTitle] = useState<string | undefined>(undefined);

  const apiVersion = useQuery('apiVersion', () => {
    return api<Info>('GET', '/');
  });

  return (
    <section className={props.className}>
      <Card body>
        <p>API Endpoint: {getEnv('API_ENDPOINT2')}</p>
        <p>API Version: {apiVersion.isSuccess && apiVersion.data.version}</p>
      </Card>
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <h4>Heading 4</h4>
      <h5>Heading 5</h5>
      <h6>Heading 6</h6>
      <hr />
      <p>
        Paragraph - Ac class platea gravida habitasse netus varius porttitor elementum quam
        imperdiet libero lectus ultrices, risus felis orci vulputate litora nisl vivamus ex at non
        mauris scelerisque. Cubilia iaculis felis vulputate nunc posuere aptent consequat
        consectetur aenean inceptos lobortis class id elit, nullam pulvinar ornare pellentesque sit
        lorem primis sagittis sociosqu tortor quam maecenas. Massa per nulla cubilia phasellus
        semper malesuada ullamcorper vitae ridiculus amet sit facilisis nunc maecenas elementum dui
        mauris, turpis cras lorem enim non nisl nisi sollicitudin facilisi tellus donec egestas
        libero consequat tortor faucibus.
      </p>
      <hr />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Accordion Item #1</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Accordion Item #2</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
            dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
            mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />
      <h2>Icons</h2>
      <Table>
        <tbody>
          <tr>
            <td>
              <code>&lt;IconCancel /&gt;</code>
            </td>
            <td>
              {' '}
              <IconCancel className={'me-3'} />
            </td>
          </tr>
          <tr>
            <td>
              <code>&lt;IconCreate /&gt;</code>
            </td>
            <td>
              {' '}
              <IconCreate className={'me-3'} />
            </td>
          </tr>
          <tr>
            <td>
              <code>&lt;IconDrag /&gt;</code>
            </td>
            <td>
              {' '}
              <IconDrag className={'me-3'} />
            </td>
          </tr>
          <tr>
            <td>
              <code>&lt;IconDelete /&gt;</code>{' '}
            </td>
            <td>
              <IconDelete className={'me-3'} />
            </td>
          </tr>
          <tr>
            <td>
              <code>&lt;IconDuplicate /&gt;</code>
            </td>
            <td>
              {' '}
              <IconDuplicate className={'me-3'} />
            </td>
          </tr>
          <tr>
            <td>
              <code>&lt;IconEdit /&gt;</code>
            </td>
            <td>
              {' '}
              <IconEdit className={'me-3'} />
            </td>
          </tr>
          <tr>
            <td>
              <code>&lt;IconSave /&gt;</code>{' '}
            </td>
            <td>
              <IconSave className={'me-3'} />
            </td>
          </tr>
          <tr>
            <td>
              <code>&lt;IconSignIn /&gt;</code>{' '}
            </td>
            <td>
              <IconSignIn className={'me-3'} />
            </td>
          </tr>
        </tbody>
      </Table>
      <hr />
      <div>
        Badges:
        <Badge bg="primary">Primary</Badge> <Badge bg="secondary">Secondary</Badge>{' '}
        <Badge bg="success">Success</Badge> <Badge bg="danger">Danger</Badge>{' '}
        <Badge bg="warning" text="dark">
          Warning
        </Badge>{' '}
        <Badge bg="info">Info</Badge>{' '}
        <Badge bg="light" text="dark">
          Light
        </Badge>{' '}
        <Badge bg="dark">Dark</Badge>
      </div>
      <hr />
      <Modal.Dialog>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Modal body text goes here.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer>
      </Modal.Dialog>
      <br />
      <div>
        <Button variant="primary">Primary</Button> <Button variant="secondary">Secondary</Button>{' '}
        <Button variant="success">Success</Button> <Button variant="warning">Warning</Button>{' '}
        <Button variant="danger">Danger</Button> <Button variant="info">Info</Button>{' '}
        <Button variant="light">Light</Button> <Button variant="dark">Dark</Button>{' '}
        <Button variant="link">Link</Button>
      </div>
      <hr />
      <div>
        Cards
        <Row>
          <Col>
            <Card style={{ width: '18rem' }}>
              <Card.Img src="https://placekitten.com/600/500" variant="top" />
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the bulk of the
                  card's content.
                </Card.Text>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroupItem>Cras justo odio</ListGroupItem>
                <ListGroupItem>Dapibus ac facilisis in</ListGroupItem>
                <ListGroupItem>Vestibulum at eros</ListGroupItem>
              </ListGroup>
              <Card.Body>
                <Card.Link href="#">Card Link</Card.Link>
                <Card.Link href="#">Another Link</Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>Featured</Card.Header>
              <Card.Body>
                <Card.Title>Special title treatment</Card.Title>
                <Card.Text>
                  With supporting text below as a natural lead-in to additional content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <hr />
      Arrows for breeding plans
      <Row>
        <Col xs={2}>
          <Arrow className={'bg-primary'} width={'100%'} />
        </Col>
        <Col xs={1}>
          <Arrow className={'bg-secondary'} width={'100%'} />
        </Col>
        <Col xs={1}>
          <Arrow className={'bg-info'} width={'100%'} />
        </Col>
        <Col xs={1}>
          <Arrow className={'bg-danger'} width={'100%'} />
        </Col>
      </Row>
      <hr />
      Alerts
      <Row>
        <Col>
          <Alert variant="success">
            <Alert.Heading>Hey, nice to see you</Alert.Heading>
            <p>
              Aww yeah, you successfully read this important alert message. This example text is
              going to run a bit longer so that you can see how spacing within an alert works with
              this kind of content.
            </p>
            <hr />
            <p className="mb-0">
              Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
            </p>
          </Alert>
        </Col>
        <Col>
          <Alert variant="danger">
            <Alert.Heading>Hey, nice to see you</Alert.Heading>
            <p>
              Aww yeah, you successfully read this important alert message. This example text is
              going to run a bit longer so that you can see how spacing within an alert works with
              this kind of content.
            </p>
            <hr />
            <p className="mb-0">
              Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
            </p>
          </Alert>
        </Col>
        <Col>
          <Alert variant="info">
            <Alert.Heading>Hey, nice to see you</Alert.Heading>
            <p>
              Aww yeah, you successfully read this important alert message. This example text is
              going to run a bit longer so that you can see how spacing within an alert works with
              this kind of content.
            </p>
            <hr />
            <p className="mb-0">
              Whenever you need to, be sure to use margin utilities to keep things nice and tidy.
            </p>
          </Alert>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Button
            className={'mb-3'}
            icon={IconCreate}
            label={'Create'}
            onClick={(): void => {
              return;
            }}
          />
        </Col>
        <Col>
          <Button
            icon={IconDelete}
            label={'Delete'}
            onClick={(): void => {
              return;
            }}
            size={'sm'}
            variant={ButtonVariant.OutlineDanger}
          />
        </Col>
        <Col>
          <Button
            icon={IconEdit}
            label={'Edit'}
            onClick={(): void => {
              return;
            }}
            size={'sm'}
            variant={ButtonVariant.OutlinePrimary}
          />
        </Col>
      </Row>
      <hr />
      <p>Use this button when needing to save on a page, not in a modal</p>
      <p>
        <code>&lt;ButtonSave /&gt;</code>
      </p>
      <p>
        <ButtonSave />
      </p>
      <hr />
      <p>Use this button in modal footers</p>
      <p>
        <code>&lt;ButtonModal /&gt;</code>
      </p>
      <p>
        <ButtonModal label={'Label'} />
        <ButtonModal disabled label={'Disabled'} />
        <ButtonModal busy disabled label={'Busy'} />
      </p>
      <hr />
      Trigger error
      <Button
        onClick={(): void => {
          throw new Error('This is a test message from an error exception.');
        }}
      >
        Error
      </Button>
      <hr />
      <Button
        onClick={(): void => {
          setShowToast(true);
        }}
      >
        Trigger Toast for 5 sec, close on click
      </Button>
      <Toast
        closeOnClick={true}
        delay={5000}
        onClose={(): void => setShowToast(false)}
        show={showToast}
      >
        This is the toast message
      </Toast>
      <hr />
      <h2>Animal Milking Statuses</h2>
      <p style={{ fontSize: '24px' }}>
        <AnimalMilkingStatusBadge status={AnimalMilkingStatus.Dry} />
        <AnimalMilkingStatusBadge status={AnimalMilkingStatus.Lactating} />
        <AnimalMilkingStatusBadge status={AnimalMilkingStatus.Unknown} />
      </p>
      <hr />
      <h2>Animal Reproduction Statuses</h2>
      <p style={{ fontSize: '24px' }}>
        <AnimalReproductionStatusBadge status={AnimalReproductionStatus.Bred} />
        <AnimalReproductionStatusBadge status={AnimalReproductionStatus.Open} />
        <AnimalReproductionStatusBadge status={AnimalReproductionStatus.Pregnant} />
        <AnimalReproductionStatusBadge status={AnimalReproductionStatus.Unknown} />
      </p>
      <hr />
      <h2>Animal Dead/Sold bades</h2>
      <p style={{ fontSize: '24px' }}>
        <AnimalDiedBadge />
        <AnimalSoldBadge />
      </p>
      <hr />
      <h2>Product Autocomplete</h2>
      <ProductAutocomplete
        id="product1"
        multiple
        onSelect={setSelectedProducts}
        selected={selectedProducts}
      />
      <hr />
      <LabelledComponent label="Animal Group Autocomplete">
        <AnimalGroupAutocomplete
          id="animalGroup1"
          multiple
          onSelect={setSelectedAnimalGroups}
          selected={selectedAnimalGroups}
        />
      </LabelledComponent>
      <LabelledComponent label="Task title Autocomplete">
        <TaskTitleAutocomplete
          id="animalGroup1"
          multiple
          onTemplateChanged={function (value: TaskTemplate | undefined): void {
            setTaskTitle(value?.title ?? '');
          }}
          onTitleChanged={function (value: string | undefined): void {
            setTaskTitle(value ?? '');
          }}
          title={taskTitle}
        />
      </LabelledComponent>
      <p className={'mt-10'}>&nbsp;</p>
    </section>
  );
};

function LabelledComponent(props: React.PropsWithChildren<{ label: string }>): JSX.Element {
  return (
    <>
      <h2>{props.label}</h2>
      {props.children}
      <hr />
    </>
  );
}

export default StylesPage;
