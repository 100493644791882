// Copyright © 2024 CATTLEytics Inc.

import { ApiResourceV1, HttpMethod } from '../../shared';
import FileEntity from '../entities/file';
import { SignedUrlType } from '../enums';
import { api } from '../utilities';

/**
 *
 * @param file
 * @param signedUrlType
 * @param siteId
 * @returns
 */
const uploadFile = async (
  file: File,
  signedUrlType?: SignedUrlType,
  siteId?: number,
): Promise<FileEntity> => {
  const data = new FormData();
  data.append('file', file);

  const response = await api<FileEntity, FormData>(
    HttpMethod.Post,
    `${ApiResourceV1.Files}?signedUrlType=${signedUrlType ?? SignedUrlType.Original}${
      siteId !== undefined ? `&siteId=${siteId}` : ''
    }`,
    {
      body: data,
      stringifyBody: false,
      replaceHeaders: true,
      headers: {
        'X-Milkshake-Token': 'Milkshake',
      },
    },
  );

  return response as FileEntity;
};

/**
 *
 * @param key
 * @param signedUrlType
 * @returns
 */
const getFile = async (key: string, signedUrlType?: SignedUrlType): Promise<FileEntity> => {
  const result = await api<FileEntity>(HttpMethod.Get, `${ApiResourceV1.Files}/${key}`, {
    body: { signedUrlType: signedUrlType ?? 'original' },
  });
  if (!result) {
    throw new Error('No result');
  }
  return result;
};

const deleteFile = async (id: string): Promise<null> => {
  return await api(HttpMethod.Delete, `${ApiResourceV1.Files}/${id}`);
};

interface FileServiceResult {
  /**
   * Remove the file from the server.
   */
  delete(id: string): Promise<null>;

  /**
   * Retrieve the file from the server.
   */
  get(key: string, signedUrlType?: SignedUrlType): Promise<FileEntity>;

  /**
   * Send a file to the server.
   */
  upload(file: File, signedUrlType?: SignedUrlType, siteId?: number): Promise<FileEntity>;
}

/**
 * Access the file api
 *
 * Use this instead of useInjection<FileService>();
 */
export function useFileService(): FileServiceResult {
  return { delete: deleteFile, get: getFile, upload: uploadFile };
}
