// Copyright © 2024 CATTLEytics Inc.

import { useState } from 'react';
import { Col, Form, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';

import Spinner from '../../common/components/Spinner';
import { QueryKey, TaskCategory } from '../../shared';
import { TaskCategoryAutocomplete } from './TaskCategoryAutocomplete';
import { TaskTemplateTable } from './TaskTemplateTable';

export interface TaskTableFilters {
  // assignedTo?: User[];
  categories?: TaskCategory[];
  // completed?: 'show' | 'only' | 'hide';
  // dateRange?: { endDate?: Date | null; startDate?: Date | null };

  search?: string;
}

export interface TaskTableProps {
  filters?: TaskTableFilters;
  initialLimit?: number;
}
export interface TaskTemplateTableWithFiltersProps extends TaskTableProps {
  showCategoryFilters?: boolean;
  showFilters?: boolean;
}

export function TaskTemplateTableWithFilters({
  showFilters = true,
  showCategoryFilters = true,
  ...props
}: TaskTemplateTableWithFiltersProps): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [categories, setCategories] = useState<TaskCategory[]>([]);

  const fetchingTasks = useIsFetching({ queryKey: [QueryKey.Tasks] });

  return (
    <>
      {showFilters && (
        <Stack className="w-100" direction="horizontal" gap={3}>
          <Form className="w-100 d-flex gap-2">
            <Form.Group className="flex-grow-2">
              <Form.Label column>{t('taskTemplateTable|searchLabel')}</Form.Label>
              <Col className="flex-grow-1">
                <Form.Control
                  onChange={(ev): void => setSearch(ev.currentTarget.value)}
                  placeholder={t('taskTemplateTable|searchPlaceholder')}
                  type="text"
                  value={search}
                />
              </Col>
            </Form.Group>

            {showCategoryFilters && (
              <Form.Group className="flex-grow-1">
                <Form.Label column>{t('taskTemplateTable|categoryLabel')}</Form.Label>
                <Col>
                  <TaskCategoryAutocomplete
                    id={''}
                    multiple
                    onSelect={(category?: TaskCategory | TaskCategory[] | undefined): void => {
                      setCategories(
                        category === undefined
                          ? []
                          : Array.isArray(category)
                          ? category
                          : [category],
                      );
                    }}
                    selected={categories}
                  />
                </Col>
              </Form.Group>
            )}

            <Col className="flex-shrink-1">{fetchingTasks > 0 && <Spinner />}</Col>
          </Form>
        </Stack>
      )}

      <TaskTemplateTable {...props} filters={{ search, categories }} />
    </>
  );
}
