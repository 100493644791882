// Copyright © 2023 CATTLEytics Inc.

import React, {
  ChangeEvent,
  ChangeEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { ApiResourceV1, HttpMethod, QueryKey, TaskCategory } from '../../shared';
import { TaskCategoryModal } from '../../tasks/components/TaskCategoryModal';
import { api } from '../utilities';

/**
 * Component input properties.
 */
interface Props {
  /**
   * Accessibility label
   */
  ariaLabel?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  isInvalid?: boolean;

  isValid?: boolean;

  /**
   * form element name
   */
  name?: string;

  /**
   * input change handler
   * @param event
   */
  onChange: ChangeEventHandler<HTMLSelectElement>;

  /**
   * when add modal is closed
   */
  onCloseAddModal?: () => void;

  /**
   * whether the field is required
   */
  required?: boolean;

  /**
   * whether to display the add modal
   */
  showAddModal?: boolean;
  /**
   * input value
   */
  value?: number;
}

/**
 * Form select component for task categories.
 */
export const TaskCategorySelect = (props: PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const [showAddModal, setShowAddModal] = useState<boolean>(!!props.showAddModal);
  const [previousShowAddModal, setPreviousShowAddModal] = useState<boolean>();

  const query = useQuery<TaskCategory[]>(
    [QueryKey.TaskCategories],
    () =>
      api<TaskCategory[]>(HttpMethod.Get, ApiResourceV1.TaskCategories, {
        params: { limit: '100' },
      }),
    {},
  );

  useEffect(() => {
    if (query.data && (!props.value || props.value === -1)) {
      // if there is a category with the name "General" make it default
      // else if we have at least one category make the first one the default
      const general = query.data.find((cat) => cat.name === 'General');
      if (general) {
        // props.onChange(general.id);
        props.onChange({
          target: { name: props.name, value: general.id },
        } as unknown as ChangeEvent<HTMLSelectElement>);
      } else if (query.data.length > 0) {
        props.onChange({
          target: { name: props.name, value: query.data[0].id },
        } as unknown as ChangeEvent<HTMLSelectElement>);
      }
    }
  }, [query.data, props]);

  const items = query.data;
  const value = props.value ?? undefined;

  if (!!props.showAddModal !== previousShowAddModal) {
    setShowAddModal(!!props.showAddModal);
    setPreviousShowAddModal(!!props.showAddModal);
  }

  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? t('taskCategorySelect|ariaLabel')}
        className={props.className}
        isInvalid={props.isInvalid}
        isValid={props.isValid}
        name={props.name ?? 'taskCategoryId'}
        onChange={props.onChange}
        required={props.required}
        value={value}
      >
        <option disabled value="">
          {query.isFetching
            ? `${t('taskCategorySelect|loadingOption')}...`
            : t('taskCategorySelect|chooseOption')}
        </option>
        {items &&
          items.map((item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            );
          })}
      </Form.Select>
      {showAddModal && (
        <TaskCategoryModal onClose={props.onCloseAddModal ?? ((): void => undefined)} />
      )}
    </>
  );
};
