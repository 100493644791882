// Copyright © 2023 CATTLEytics Inc.

import React, { CSSProperties, isValidElement, useEffect, useState } from 'react';
import { Col, Form, FormControl, FormGroup, Row, Table, TableProps } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'usehooks-ts';

import { Sort } from '../enums';
import { IconCaretRight } from '../utilities';
import PaginationControls from './PaginationControls';
import TableBusyIndicator from './TableBusyIndicator';
import TableHeadingColumn from './TableHeadingColumn';
import TablePlaceholder from './TablePlaceholder';

export interface DataTableHeader {
  /**
   * Class to apply to header column (th)
   */
  className?: string;

  /**
   * Mobile only CSS classes
   */
  classNameMobile?: string;

  /**
   * A value to display under a heading column in a <code> block
   */
  code?: string;

  /**
   * Class to apply to data column (td)
   */
  colClassName?: string;

  /**
   * Whether to hide the label (column name).
   */
  hideLabel?: boolean;

  /**
   * Whether to hide this column on mobile.
   */
  hideMobile?: boolean;

  /**
   * Whether to include buttons in the column. This will block the onRowClick event.
   */
  includesButtons?: boolean;

  /**
   * Text for an info icon tooltip
   */
  infoTooltip?: string;

  /**
   * Displayable column name
   */
  label: string;

  /**
   * Column name/key
   */
  name: string;

  /**
   * Whether this column can be sorted
   */
  sortable?: boolean;

  /**
   * Any additional CSS styles to apply to the header.
   */
  style?: CSSProperties;

  /**
   * Whether this column should be displayed
   * If undefined, column will be visible.
   */
  visible?: boolean;
}

export interface DataTableRow {
  [key: string]: string | JSX.Element | undefined;
}

/**
 * Defines the input properties for this component.
 */
interface Props {
  /**
   * The breakpoint which the table will be shown
   * Default: sm
   */
  breakpoint?: 'sm' | 'md' | 'lg' | 'xl';

  /**
   * CSS properties to apply to component
   */
  className?: string;

  /**
   * The data rows
   */
  data: DataTableRow[];

  /**
   * Column headers
   */
  headers: DataTableHeader[];

  /**
   * Whether to hide/show pagination controls.
   */
  hidePaginationControls?: boolean;

  /**
   * Whether the data is loading
   */
  isLoading?: boolean;

  /**
   * Whether the data is loading while there is already existing data displayed
   */
  isPreviousData?: boolean;

  /**
   * The number of items to display in the table
   */
  limit?: number;

  /**
   * Message to display when table contains no data.
   */
  messageNoData?: string;

  /**
   * The data record offset
   */
  offset?: number;

  /**
   * Callback when limit changes
   * @param newLimit
   */
  onLimitChange?: (newLimit: number) => void;

  /**
   * Callback when offset changes
   * @param newOffset
   */
  onOffsetChange?: (newOffset: number) => void;

  /**
   * Callback when data row is clicked.
   */
  onRowClick?: (row: DataTableRow) => void;

  /**
   * Callback when search term changes.
   * @param query
   */
  onSearchChange?: (query: string) => void;

  /**
   * Callback when sort direction changes
   * @param newSortDirection
   */
  onSortDirectionChange?: (newSortDirection: Sort) => void;

  /**
   * Callback when sort field changes
   * @param newSortField
   */
  onSortFieldChange?: (newSortField: string) => void;

  /**
   * Search term to filter list by.
   */
  search?: string;

  /**
   * Whether to show search.
   * Default: false
   */
  showSearch?: boolean;

  /**
   * The direction of the sort
   * Default: Ascending
   */
  sortDirection?: Sort;

  /**
   * The field to sort the data
   */
  sortField?: string;

  /**
   * Properties to apply directly to the Table component
   */
  tableProps?: TableProps;
}

/**
 * Component for displaying and navigation through tabular data
 */
const DataTable = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  const limit = props.limit ?? 25;
  const offset = props.offset ?? 0;
  if (!props.headers || props.headers.length === 0) {
    throw Error('headers is a required prop');
  }
  const headers = props.headers.filter((col) => col.visible === undefined || col.visible);
  const breakpoint = props.breakpoint ?? 'sm';
  const className = props.className ?? 'mt-3';

  const [searchTerm, setSearchTerm] = useState<string>(props.search ?? '');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const sortTable = (field: string): void => {
    if (!props.sortField) {
      return;
    }
    if (field !== props.sortField) {
      props.onSortFieldChange && props.onSortFieldChange(field);
      props.onSortDirectionChange && props.onSortDirectionChange(Sort.Ascending);
    } else if (field === props.sortField && props.sortDirection === Sort.Descending) {
      props.onSortDirectionChange && props.onSortDirectionChange(Sort.Ascending);
    } else if (field === props.sortField && props.sortDirection === Sort.Ascending) {
      props.onSortDirectionChange && props.onSortDirectionChange(Sort.Descending);
    }
  };

  const sortTableField = (field: string): void => {
    if (!props.sortField) {
      return;
    }

    props.onSortFieldChange && props.onSortFieldChange(field);
  };

  const sortTableDirection = (direction: Sort): void => {
    if (!props.sortDirection) {
      return;
    }

    props.onSortDirectionChange && props.onSortDirectionChange(direction);
  };
  const { onSearchChange } = props;
  useEffect(() => {
    if (debouncedSearchTerm) {
      const search = (query: string): void => {
        if (!onSearchChange) {
          return;
        }
        onSearchChange && onSearchChange(query);
      };
      search(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, onSearchChange]);

  if (props.isLoading) {
    return (
      <div className={className}>
        <TablePlaceholder />
      </div>
    );
  }

  return (
    <div className={className}>
      {props.showSearch && (
        <FormGroup>
          <FormControl
            name={'search'}
            onChange={(e): void => setSearchTerm(e.target.value)}
            placeholder={t('dataTable|searchPlaceholder')}
            value={searchTerm}
          />
        </FormGroup>
      )}
      <Row className={'d-sm-none mb-3'}>
        <Col xs={12}>
          <small>{t('dataTable|sortByLabel')}:</small>
        </Col>
        <Col xs={6}>
          <Form.Select onChange={(header): void => sortTableField(header.target.value)}>
            {headers
              .filter((header) => !header.sortable)
              .map((header, index) => (
                <option key={index} value={header.name}>
                  {header.label}
                </option>
              ))}
          </Form.Select>
        </Col>
        <Col xs={6}>
          <Form.Select onChange={(header): void => sortTableDirection(header.target.value as Sort)}>
            <option value={Sort.Ascending}>{t('dataTable|ascending')}</option>
            <option value={Sort.Descending}>{t('dataTable|descending')}</option>
          </Form.Select>
        </Col>
      </Row>
      {props.isPreviousData && <TableBusyIndicator />}
      <div className={`d-none d-${breakpoint}-block`}>
        <Table {...props.tableProps} hover responsive striped>
          <thead>
            <tr>
              {headers.map((header, index) => {
                // if sortable prop is undefined make it true
                const sortable = header.sortable === undefined ? true : header.sortable;
                return (
                  <TableHeadingColumn
                    className={header.className}
                    code={header.code}
                    infoTooltip={header.infoTooltip}
                    key={index}
                    label={header.label}
                    notSortable={!sortable}
                    onClick={(): void => {
                      if (header.sortable === undefined || header.sortable) {
                        sortTable(header.name);
                      }
                    }}
                    showSortIndicator={props.sortField === header.name}
                    sortDirection={props.sortDirection ?? Sort.Ascending}
                    style={header.style}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {props.data.length === 0 && (
              <tr>
                <td className={'text-center'} colSpan={props.headers.length}>
                  {props.messageNoData ?? t('dataTable|messageNoData')}
                </td>
              </tr>
            )}
            {props.data.map((row, index) => {
              const colClassName = row['colClassName'] ?? '';
              return (
                <tr
                  className={`${props.onRowClick ? 'clickable' : ''} ${colClassName}`}
                  key={index}
                  onClick={(): void => props.onRowClick && props.onRowClick(row)}
                  style={{ cursor: props.onRowClick ? 'pointer' : 'default' }}
                >
                  {headers.map((header, index) => {
                    const value = row[header.name];
                    const includesButtons = header.includesButtons ?? false;
                    return (
                      <td
                        className={`${header.colClassName ?? ''} ${colClassName}`}
                        key={index}
                        onClick={(ev): void => {
                          // if includesButtons is true, don't trigger onRowClick

                          if (includesButtons) {
                            ev.preventDefault();
                            ev.stopPropagation();
                            return;
                          }
                        }}
                      >
                        {typeof value !== 'object' || isValidElement(value) ? value : ''}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div className={`d-${breakpoint}-none`}>
        {props.data.length === 0 && (
          <div className={'my-5 text-center'}>
            {props.messageNoData ?? t('dataTable|messageNoData')}
          </div>
        )}
        {props.data.map((row, index) => (
          <div
            className={`d-flex mb-2 p-2 ${props.onRowClick ? 'clickable' : ''}`}
            key={index}
            onClick={(): void => props.onRowClick && props.onRowClick(row)}
            style={{
              backgroundColor: (index + 1) % 2 ? 'var(--striped)' : 'transparent',
              //borderBottom: '1px solid var(--striped)',
              cursor: props.onRowClick ? 'pointer' : 'default',
            }}
          >
            <Row className={'gx-1'} style={{ flexGrow: 1 }}>
              {headers
                .filter((header) => !header.hideMobile)
                .map((header, index) => {
                  const value = row[header.name];
                  return (
                    <div className={header.classNameMobile ?? ''} key={index}>
                      <dl>
                        {!header.hideLabel && <dt>{header.label}</dt>}
                        <dd className={'text-truncate'}>
                          {typeof value !== 'object' || isValidElement(value) ? value : ''}
                        </dd>
                      </dl>
                    </div>
                  );
                })}
            </Row>
            <div className={'align-self-center'} style={{ justifySelf: 'end' }}>
              <IconCaretRight />
            </div>
          </div>
        ))}
      </div>
      {!props.hidePaginationControls && (
        <PaginationControls
          disabled={props.isPreviousData || props.isLoading}
          end={props.data && props.data.length < limit}
          limit={limit}
          onLimitChange={(newLimit): void => props.onLimitChange && props.onLimitChange(newLimit)}
          onNext={(): void => props.onOffsetChange && props.onOffsetChange(offset + limit)}
          onPrev={(): void => props.onOffsetChange && props.onOffsetChange(offset - limit)}
          start={offset === 0}
        />
      )}
    </div>
  );
};

export default DataTable;
