// Copyright © 2024 CATTLEytics Inc.

import { Badge } from 'react-bootstrap';
import * as Icons from 'react-icons/io5';

import { TaskCategory } from '../../shared';

/**
 * Nicer display for category tabs.
 */
export function CategoryTab({
  category,
  count,
}: {
  category: TaskCategory;
  count?: number;
}): JSX.Element {
  const Icon = category.iconName
    ? Icons[category.iconName as keyof typeof Icons]
    : Icons.IoDocumentTextOutline;

  return (
    <div className="d-flex align-items-center">
      <Icon className="me-1" />
      {category.name}

      {category.t}
      <Badge>{count}</Badge>
    </div>
  );
}
