// Copyright © 2024 CATTLEytics Inc.

import { SettingsSchedule } from '../../shared';
import { useSettings } from './useSettings';

interface UseSettingsScheduleResult {
  scheduleAllowSwapping: boolean;
  scheduleRequestApproval: boolean;
}

/**
 * Parsed settings for the schedule from the Settings API.
 */
export function useSettingsSchedule(): UseSettingsScheduleResult {
  const query = useSettings<SettingsSchedule>({ key: 'schedule' });
  return {
    // ...(query.data ? query.data : {}),
    scheduleAllowSwapping: (query.data?.allowSwapping ?? 'false') === 'true',
    scheduleRequestApproval: (query.data?.requestApproval ?? 'false') === 'true',
  };
}
