// Copyright © 2024 CATTLEytics Inc.

import { addDays, addHours, endOfDay, isPast, isToday, parseISO, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { isNullOrWhitespace, Task, TaskPriority } from '../../shared';

export function trimTo(description?: string, size = 50): string {
  const validDescription = description?.trim() ?? '';
  return validDescription.length > size
    ? validDescription.substring(0, size) + '...'
    : validDescription;
}

interface TaskState {
  asap: boolean;
  overdue: boolean;
  thisWeek: boolean;
  today: boolean;
  whenever: boolean;
}

export function getTaskStatus(state: TaskState): string {
  const taskGroupName = state.overdue
    ? 'Overdue'
    : state.today
    ? 'Today'
    : state.thisWeek
    ? 'Week'
    : 'Whenever';
  return taskGroupName;
}

export function getTaskState(task: Task, timeZone: string): TaskState {
  const now = new Date();
  const todayStart = startOfDay(now);
  const todayEnd = endOfDay(now);
  const weekStart = addDays(startOfDay(now), 1);
  const weekEnd = addDays(endOfDay(weekStart), 7);

  const isOverdue =
    task.completed === false &&
    !!task.dueDate &&
    !isToday(parseISO(task.dueDate)) &&
    isPast(parseISO(task.dueDate));

  const isTodayTask = task.startDate
    ? utcToZonedTime(task.startDate, timeZone) >= todayStart &&
      utcToZonedTime(task.startDate, timeZone) <= todayEnd
    : false;
  const isWeekTask = task.startDate
    ? utcToZonedTime(task.startDate, timeZone) >= weekStart &&
      utcToZonedTime(task.startDate, timeZone) <= weekEnd
    : false;

  return {
    overdue: isOverdue,
    today: isTodayTask,
    thisWeek: isWeekTask,
    whenever: false,
    asap: false,
  };
}

export function getTaskStateByDueDate(dueDate: string): TaskState {
  if (isNullOrWhitespace(dueDate)) {
    return {
      overdue: false,
      today: false,
      thisWeek: false,
      whenever: true,
      asap: false,
    };
  }
  const validDueDate = parseISO(dueDate);
  const isOverdue = !!dueDate && !isToday(validDueDate) && isPast(validDueDate);

  const isTodayTask = validDueDate.toISOString() === endOfDay(new Date()).toISOString();
  const isWeekTask = validDueDate.toISOString() === endOfDay(addDays(new Date(), 7)).toISOString();
  const isAsap = validDueDate.toISOString() === addHours(new Date(), 1).toISOString();
  const isWhenever = !isTodayTask && !isWeekTask && !isAsap;

  return {
    overdue: isOverdue,
    today: isTodayTask,
    thisWeek: isWeekTask,
    whenever: isWhenever,
    asap: isAsap,
  };
}

export function getPriorityOrdered(): TaskPriority[] {
  const items = Object.values([
    TaskPriority.Highest,
    TaskPriority.High,
    TaskPriority.Medium,
    TaskPriority.Low,
    TaskPriority.Lowest,
  ]);

  return items as TaskPriority[];
}
