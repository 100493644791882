// Copyright © 2024 CATTLEytics Inc.

import { useMemo, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { api, IconCancel } from '../../common/utilities';
import {
  ApiResourceV1,
  HttpMethod,
  isNullOrWhitespace,
  QueryKey,
  TaskTemplate,
} from '../../shared';
import { WithCount } from './utilities';

interface TaskTitleAutocompleteProps {
  /**
   * Loading indicator.
   */
  busy?: boolean;

  /**
   * HTML ID attribute
   */
  id: string;

  isInvalid?: boolean;

  isValid?: boolean;

  /**
   * Whether to allow multiple pen selection.
   *
   * Note: this also changes the parameters sent in the onSelect callback from
   * single item to array of items.
   */
  multiple?: boolean;

  name?: string;

  /**
   * Callback after template has been selected
   */
  onTemplateChanged: (value: TaskTemplate | undefined) => void;

  /**
   * Callback after title has been changed
   */
  onTitleChanged: (value: string | undefined) => void;

  /**
   * Set the typeahead placeholder text.
   */
  placeholder?: string;

  /**
   * If this field is required.
   */
  required?: boolean;

  /**
   * Initial selected option or options.
   */
  selected?: Option | Option[];

  template?: TaskTemplate;

  title: string | undefined;
  /**
   * Whether the form this component is in has been validated.
   * This will show invalid messages if applicable.
   * Note this does not indicate a form is valid.
   */
  validated?: boolean;
}

const pageSize = 50_000;

export function TaskTitleAutocomplete(props: TaskTitleAutocompleteProps): JSX.Element {
  const { t } = useTranslation();

  const [limit] = useState<number>(pageSize);
  const [offset] = useState<number>(0);
  const [resetKey, setResetKey] = useState<number>(0);

  const { data: templates, isLoading: busy } = useQuery<WithCount<TaskTemplate>>(
    [QueryKey.TaskTemplates, 'templates', limit, offset],
    () =>
      api(HttpMethod.Get, ApiResourceV1.TaskTemplates, {
        params: {
          // search: searchQuery,
          sortField: 'title',
          sortDirection: 'asc',
          limit: String(limit),
          offset: String(offset),
        },
      }),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
    },
  );

  const templateTitles = useMemo(() => {
    if (busy) {
      return [];
    }
    return templates?.items?.map((template) => template.title);
  }, [busy, templates]);

  const required = props.required ?? false;

  // if the validated property is set and true (form validation was run),
  // and the field is required and the selection is empty
  const isInvalid = props.isInvalid;

  // if the validated property is set and true (form validation was run),
  // and the field is not isInvalid
  const isValid = props.isValid;

  // The following input element is a way to trigger a javascript invalid
  // state so HTML5 form validation can be used since the input element
  // inside the typeahead component does not support this
  // if one or more items have been selected this input element will be
  // set to 1 thus satisfying the required validator
  const inputHelper = (
    <input
      aria-hidden={true}
      key={`title_hidden_${resetKey}`}
      name={props.name}
      readOnly={true}
      required={required}
      style={{ display: 'none' }}
      tabIndex={-1}
      type={'text'}
      value={props.isInvalid ? '' : '1'}
    />
  );

  return (
    <div className={`tasktitle-tag-autocomplete ${isInvalid ? 'is-invalid' : 'is-valid'}`}>
      {inputHelper}
      <InputGroup key={resetKey}>
        {!props.template && (
          <AsyncTypeahead
            allowNew={false}
            clearButton={true} // for styling only
            id={props.id}
            inputProps={{
              required: isInvalid,
              onInput: (event: React.FormEvent<HTMLInputElement>): void => {
                const target = event.target as HTMLInputElement;
                // Update the input's tag value to be lowercase
                if (target?.value) {
                  // target.value = target.value.toLowerCase();
                }
              },
            }}
            isInvalid={isInvalid}
            isLoading={busy || !!props.busy}
            isValid={isValid}
            minLength={0}
            multiple={false}
            onChange={(selected: Option[]): void => {
              const nextTitle = selected && selected.length > 0 ? (selected[0] as string) : '';
              if (!isNullOrWhitespace(nextTitle)) {
                const selectedTitle = nextTitle;
                const selectedTemplate = templates?.items?.find((t) => t.title === selectedTitle);

                if (selectedTemplate && !isNullOrWhitespace(selectedTemplate?.title ?? '')) {
                  props.onTemplateChanged(selectedTemplate);
                }
              } else {
                props.onTemplateChanged(undefined);
                props.onTitleChanged('');
                return;
              }
              props.onTitleChanged(nextTitle);
            }}
            onSearch={(searchQuery: string): void => {
              props.onTitleChanged(searchQuery);
            }}
            options={templateTitles ?? []}
            placeholder={props.placeholder ?? t('taskTitle|placeholderLabel')}
            renderMenu={(results, menuProps, state): JSX.Element => {
              return (
                <Menu {...menuProps}>
                  <Menu.Header>{t('taskTitle|templateLabel')}:</Menu.Header>
                  {results.map((option, index) => (
                    <MenuItem key={index} option={option} position={index}>
                      <Highlighter search={state.text}>{option as string}</Highlighter>
                    </MenuItem>
                  ))}
                </Menu>
              );
            }}
            selected={props.template ? [props.template] : props.title ? [props.title] : []}
          />
        )}
        {props.template && (
          <>
            <Form.Control
              autoFocus
              id={props.id}
              onChange={(ev): void => {
                props.onTitleChanged(ev.target.value);
              }}
              value={props.title}
            />
            <InputGroup.Text className="bg-primary">
              <strong className="text-white">
                {t('taskTitle|usingLabel')}: {props.template?.title}
              </strong>

              <Button
                onClick={(): void => {
                  props.onTemplateChanged(undefined);
                  props.onTitleChanged('');
                  setResetKey((prev) => prev + 1);
                }}
                size="sm"
                variant="outline-muted"
              >
                <IconCancel className="text-white" />
              </Button>
            </InputGroup.Text>
          </>
        )}
      </InputGroup>
    </div>
  );
}
