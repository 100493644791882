// Copyright © 2023 CATTLEytics Inc.

/**
 * WARNING!
 *
 * DO NOT EDIT THIS FILE
 *
 * CHANGES WILL BE OVERWRITTEN!
 *
 * Make changes in packages/shared and run npm run copy-shared-to-packages
 */

export enum JobKey {
  /**
   * As needed. Once subscribers are complete this should only be needed to fix data.
   */
  AnimalUpdate = 'AnimalUpdate',

  /**
   * Runs whenever data needs to be imported.
   */
  ImportData = 'ImportData',

  /**
   * Should only run after a successful animal event data import
   */
  ImportDataCleanupAnimalEvents = 'ImportDataCleanupAnimalEvents',

  /**
   * Should only run after a successful animal test day data import
   */
  ImportDataCleanupAnimalTestDays = 'ImportDataCleanupAnimalTestDays',

  /**
   * Should only run after a successful animal data import
   */
  ImportDataCleanupAnimals = 'ImportDataCleanupAnimals',

  /**
   * Indexes a single animal.
   */
  IndexAnimal = 'IndexAnimal',

  /**
   * Indexes a single animal event.
   */
  IndexAnimalEvent = 'IndexAnimalEvent',

  /**
   * Indexes all animal events for a site.
   */
  IndexAnimalEvents = 'IndexAnimalEvents',

  /**
   * Indexes all animals for a site.
   */
  IndexAnimals = 'IndexAnimals',

  /**
   * As needed. Once subscribers are complete this should only be needed to fix data.
   */
  PenUpdate = 'PenUpdate',

  ProcedureCreateInventory = 'ProcedureCreateInventory',

  /**
   * Destroys and re-created all indexes.
   */
  RebuildAllIndexes = 'RebuildAllIndexes',

  /**
   * Should run on app startup
   */
  Startup = 'Startup',

  TaskUpdate = 'TaskUpdate',
}
