// Copyright © 2023 CATTLEytics Inc.

import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { WithTranslation, withTranslation } from 'react-i18next';

import { TaskStatus } from '../../shared';

/**
 * Component input properties.
 */
interface Props extends WithTranslation {
  /**
   * Accessibility label.
   */
  ariaLabel?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  isInvalid?: boolean;

  isValid?: boolean;

  /**
   * Form element name.
   */
  name?: string;

  /**
   * Input change handler
   * @param event
   */
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;

  /**
   * When add modal is closed.
   */
  onCloseAddModal?: () => void;

  /**
   * Whether this field is required.
   */
  required?: boolean;

  /**
   * Whether to display the add modal.
   */
  showAddModal?: boolean;
  /**
   * Input value.
   */
  value: string;
}

/**
 * Form select component for breeds.
 */
const TaskStatusSelect = (props: React.PropsWithChildren<Props>): JSX.Element => {
  const value = props.value ?? undefined;

  const options = Object.keys(TaskStatus).map((key, index) => ({
    name: key,
    id: Object.values(TaskStatus)[index],
  }));
  return (
    <>
      <Form.Select
        aria-label={props.ariaLabel ?? 'Task Status'}
        className={props.className}
        isInvalid={props.isInvalid}
        isValid={props.isValid}
        name={props.name ?? 'taskStatusId'}
        onChange={props.onChange}
        required={props.required}
        value={value}
      >
        <option>Choose a status</option>
        {options.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Form.Select>
    </>
  );
};

export default withTranslation()(TaskStatusSelect);
