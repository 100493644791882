// Copyright © 2023 CATTLEytics Inc.

import { useCallback } from 'react';
import { Stack } from 'react-bootstrap';

import DatePicker, { DatePickerProps } from './DatePicker';

type Props<CustomModifierNames extends string = never> = Omit<
  DatePickerProps<CustomModifierNames, false>,
  'selected' | 'onChange' | 'selectsRange'
> & {
  labels?: {
    end: string;
    show: boolean;
    start: string;
  };
  onEndDateChange: DatePickerProps<CustomModifierNames, false>['onChange'];
  onStartDateChange: DatePickerProps<CustomModifierNames, false>['onChange'];
};

/**
 * @deprecated Use `DateRangePicker2` instead.
 */
const DateRangePicker = <CustomModifierNames extends string = never>({
  labels,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  minDate,
  ...props
}: Props<CustomModifierNames>): JSX.Element => {
  const onStartChange = useCallback<typeof onStartDateChange>(
    (date, event) => {
      onStartDateChange(date, event);
      if (date && (!endDate || date > endDate)) {
        onEndDateChange(date, event);
      }
      setTimeout(() => {
        const elm = document.getElementById('end-date-range');
        elm?.focus();
      }, 100);
    },
    [onStartDateChange, onEndDateChange, endDate],
  );

  return (
    <Stack className="flex-wrap" direction="horizontal" gap={2}>
      {labels?.show && (
        <div>
          <label htmlFor="start-date-range">{labels?.start}</label>
        </div>
      )}
      <div style={{ width: 145 }}>
        <DatePicker
          className="text-center"
          endDate={endDate}
          id="start-date-range"
          minDate={minDate}
          onChange={onStartChange}
          selected={startDate}
          selectsRange={false}
          selectsStart
          showMonthDropdown
          showYearDropdown
          startDate={startDate}
          useShortMonthInDropdown
          {...props}
        />
      </div>
      {labels?.show && (
        <div>
          <label htmlFor="end-date-range">{labels?.end}</label>
        </div>
      )}
      <div style={{ width: 145 }}>
        <DatePicker
          className="text-center"
          endDate={endDate}
          id="end-date-range"
          minDate={startDate}
          onChange={onEndDateChange}
          selected={endDate}
          selectsEnd
          selectsRange={false}
          showMonthDropdown
          showYearDropdown
          startDate={startDate}
          useShortMonthInDropdown
          {...props}
        />
      </div>
    </Stack>
  );
};

export default DateRangePicker;
