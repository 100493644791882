// Copyright © 2024 CATTLEytics Inc.

import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronsDown, FiChevronsUp, FiChevronUp } from 'react-icons/fi';

import { TaskPriority } from '../../shared';
import { getPriorityOrdered } from './utilities';

export interface TaskPriorityPickerProps {
  errors?: any;
  isInvalid?: boolean;
  isValid?: boolean;
  onPriorityChanged: (priority: TaskPriority) => void;
  priority: TaskPriority;
}

export function TaskPriorityLabel(props: { priority: TaskPriority }): JSX.Element {
  const { t } = useTranslation();
  return (
    <>
      {props.priority === 'highest' && <FiChevronsUp />}
      {props.priority === 'high' && <FiChevronUp />}
      {props.priority === 'low' && <FiChevronDown />}
      {props.priority === 'lowest' && <FiChevronsDown />}
      {t(`taskPriority|${props.priority}`)}
    </>
  );
}

export function TaskPriorityPicker(props: TaskPriorityPickerProps): JSX.Element {
  return (
    <>
      {getPriorityOrdered().map((value) => (
        <Form.Check className="border-1" id={`check-api-radio-${value}`} inline type={'radio'}>
          <Form.Check.Input
            checked={props.priority === value}
            isInvalid={props.isInvalid}
            isValid={props.isValid}
            onChange={(): void => {
              props.onPriorityChanged(value as TaskPriority);
            }}
            type={'radio'}
            value={value}
          />
          <Form.Check.Label>
            <TaskPriorityLabel priority={value} />
          </Form.Check.Label>
          <Form.Control.Feedback type="invalid">{props.errors}</Form.Control.Feedback>
        </Form.Check>
      ))}
    </>
  );
}
