// Copyright © 2024 CATTLEytics Inc.

import { useQuery, UseQueryResult } from 'react-query';

import { api } from '../../common/utilities';
import { ApiResourceV1, HttpMethod, QueryKey } from '../../shared';

export function useSettings<T>({ key }: { key: string }): UseQueryResult<T> {
  const query = useQuery<T>(
    [QueryKey.Settings, key],
    () => api(HttpMethod.Get, `${ApiResourceV1.Settings}/${key}`),
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: true,
    },
  );

  return query;
}
