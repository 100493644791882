// Copyright © 2023 CATTLEytics Inc.

import React, { PropsWithChildren, ReactNode } from 'react';
import { Modal as BootStrapModal, ModalProps as BootStrapModalProps } from 'react-bootstrap';

/**
 * Properties for the @see Modal component.
 */
export interface Props extends BootStrapModalProps {
  /**
   * Whether the modal can be hidden when clicking on the backdrop.
   */
  allowHide?: boolean;

  bodyClassName?: string;

  /**
   * Additional class names to pass to the component.
   */
  className?: string;

  closeVariant?: string;

  extraModalProps?: BootStrapModalProps;

  /**
   * What to include in the modal footer, if anything
   */
  footer?: JSX.Element;

  /**
   * Breakpoint to show the modal in fullscreen.
   */
  fullscreen?: string | true;

  /**
   * Render prop invoked when the modal is closed.
   */
  onClose?: () => void;
  scrollable?: boolean;

  /**
   * Title of the modal.
   */
  title?: string;

  titleNode?: ReactNode;

  /**
   * Whether the modal is visible.
   */
  visible: boolean;
}

/**
 * Simple modal dialog.
 */
const Modal = (props: PropsWithChildren<Props>): JSX.Element => {
  const size = props.size ?? 'sm';
  const fullscreen = props.fullscreen ?? 'lg-down';

  return (
    <BootStrapModal
      backdrop={props.allowHide ? true : 'static'}
      centered
      className={props.className}
      fullscreen={fullscreen}
      onHide={(): void => props.onClose && props.onClose()}
      scrollable={!!props.scrollable}
      show={props.visible}
      size={size}
      {...props.extraModalProps}
    >
      <BootStrapModal.Header closeButton closeVariant={props.closeVariant}>
        {(props.title || props.titleNode) && (
          <BootStrapModal.Title>{props.titleNode ?? props.title}</BootStrapModal.Title>
        )}
      </BootStrapModal.Header>
      <BootStrapModal.Body className={props.bodyClassName}>{props.children}</BootStrapModal.Body>
      {props.footer ? <BootStrapModal.Footer>{props.footer}</BootStrapModal.Footer> : null}
    </BootStrapModal>
  );
};

export default Modal;
