// Copyright © 2024 CATTLEytics Inc.

import { useSettings } from './useSettings';

/**
 * Parsed settings for the schedule from the Settings API.
 */
export function useFeatureFlags(): Record<string, string> {
  const query = useSettings<Record<string, string>>({ key: 'feature-flags' });
  return {
    ...(query.data ? query.data : {}),
    //scheduleAllowSwapping: (query.data?.allowSwapping ?? 'false') === 'true',
    //scheduleRequestApproval: (query.data?.requestApproval ?? 'false') === 'true',
  };
}
