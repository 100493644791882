// Copyright © 2023 CATTLEytics Inc.

import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import Modal from '../../common/components/Modal';

/**
 * Component's input properties.
 */
interface Props extends WithTranslation {
  /**
   * Image displayed in modal
   */
  imageUrl?: string;

  /**
   * Callback when modal is closed.
   */
  onClose: () => void;
}

/**
 * Modal for displaying images.
 */
const LightBoxModal = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <Modal
      allowHide={true}
      className={'lightbox-modal'}
      closeVariant={'white'}
      fullscreen={'md-down'}
      onClose={props.onClose}
      size={'xl'}
      title={''}
      visible={true}
    >
      <div className={'d-flex justify-content-center'}>
        <img alt={'Lightbox'} className="rounded shadow" src={props.imageUrl} />
      </div>
    </Modal>
  );
};

export default withTranslation()(LightBoxModal);
