// Copyright © 2023 CATTLEytics Inc.
import { mdiProgressCheck } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

import { TaskStatus } from '../../shared';
import { IconComplete, IconNotComplete } from '../utilities';

interface Props {
  overdue: boolean;
  size?: string;
  status: TaskStatus;
}
export const TaskStatusIcon = ({ size = '48px', ...props }: Props): JSX.Element => {
  const completeColor = props.overdue ? 'var(--bs-danger)' : 'var(--bs-success)';
  const inProgressColor = props.overdue ? 'var(--bs-danger)' : 'var(--bs-warning)';
  const notCompleteColor = props.overdue ? 'var(--bs-danger)' : 'var(--bs-secondary)';

  switch (props.status) {
    case TaskStatus.Completed:
      return <IconComplete style={{ height: size, width: size, color: completeColor }} />;
    case TaskStatus.InProgress:
      return <Icon path={mdiProgressCheck} style={{ color: inProgressColor, width: size }} />;
    default:
      return <IconNotComplete style={{ color: notCompleteColor, width: size, height: size }} />;
  }
};
